import * as i0 from '@angular/core';
import { InjectionToken, inject, ElementRef, input, linkedSignal, effect, untracked, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
const _c0 = ["*"];
const OPTIONS_TOKEN = new InjectionToken('OPTIONS_TOKEN');
class OptionsProvider {
  constructor(options) {
    this.options = options;
  }
}

/**
 * Converts a given string to UpperCamelCase (PascalCase).
 *
 * This function transforms the input string by:
 * 1. Converting the entire string to lowercase.
 * 2. Capitalizing the first letter of each word or uppercase letter.
 * 3. Removing any hyphens or underscores.
 *
 * @example
 * ```ts
 *  upperCamelCase('foo-bar'); // FooBar
 * ```
 *
 * @param str - The input string to be converted.
 * @returns The transformed string in UpperCamelCase format.
 */
function upperCamelCase(str) {
  return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, firstLetter => {
    return firstLetter.toUpperCase();
  }).replace(/[-_]/g, '');
}

/**
 * A component that renders Tabler icons as SVG elements.
 *
 * This component takes an icon name as input and renders the corresponding SVG icon
 * by injecting it into the DOM. It supports multiple icon sets through an options token
 * and provides warnings when icons are not found.
 *
 * @example
 * ```html
 * <tabler-icon name="user"></tabler-icon>
 * ```
 *
 * @remarks
 * The component uses Angular's dependency injection to get ElementRef and options.
 * Icons are rendered using innerHTML after being retrieved from the configured icon sets.
 * Warning messages are logged to the console when icons are not found (unless warnings are ignored in options).
 */
class TablerIconComponent {
  constructor() {
    this.#elem = inject(ElementRef);
    this.#options = inject(OPTIONS_TOKEN);
    /**
     * Icon name in kebab-case format.
     * @example
     * - "user"
     * - "heart-filled"
     * - "camera-off"
     * - "brand-github"
     * etc
     */
    this.name = input.required();
    this.#svg = linkedSignal(() => this.#getSvgIcon(this.name()));
    this.setNativeSvg = effect(() => {
      const svg = this.#svg();
      if (!svg) {
        return;
      }
      // Set innerHTML to render SVG
      untracked(() => {
        this.#elem.nativeElement.innerHTML = svg;
      });
    });
  }
  #elem;
  #options;
  #svg;
  /**
   * Retrieves the SVG markup for a given icon name from the configured icon sets.
   *
   * @param iconName - The name of the icon to retrieve in kebab-case format
   * @returns The SVG markup string for the icon, or an empty string if not found
   *
   * @remarks
   * This method:
   * - Merges all icon sets from the configured options
   * - Converts the icon name to upper camel case and prepends "Icon"
   * - Returns empty string if icon name is undefined
   * - Logs a warning if icon is not found (unless warnings are ignored in options)
   */
  #getSvgIcon(iconName) {
    // Merge all icon sets from options
    const icons = Object.assign({}, ...this.#options.map(option => option.icons));
    if (!iconName) {
      return '';
    }
    const icon = `Icon${upperCamelCase(iconName)}`;
    // Use optional chaining and nullish coalescing for safer property access
    const svg = icons?.[icon] ?? '';
    if (!svg && !this.#options.some(option => option.ignoreWarnings)) {
      console.warn(`Tabler Icon not found: ${iconName}\n
         Refer to documentation on https://github.com/pierreavn/angular-tabler-icons`);
    }
    return svg;
  }
  static {
    this.ɵfac = function TablerIconComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TablerIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TablerIconComponent,
      selectors: [["i-tabler"], ["tabler-icon"]],
      inputs: {
        name: [1, "name"]
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function TablerIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: ["[_nghost-%COMP%]{display:inline-block;width:24px;height:24px;fill:none;stroke:currentColor;stroke-width:2px;stroke-linecap:round;stroke-linejoin:round}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TablerIconComponent, [{
    type: Component,
    args: [{
      selector: 'i-tabler, tabler-icon',
      template: `<ng-content />`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      styles: [":host{display:inline-block;width:24px;height:24px;fill:none;stroke:currentColor;stroke-width:2px;stroke-linecap:round;stroke-linejoin:round}\n"]
    }]
  }], null, null);
})();

/**
 * @deprecated
 *
 */
class TablerIconsModule {
  constructor() {
    this.options = inject(OPTIONS_TOKEN, {
      optional: true
    });
    if (!this.options) {
      throw new Error(`No icon provided. Make sure to use 'TablerIconsModule.pick({ ... })' when importing the module\n` + `Refer to documentation on https://github.com/pierreavn/angular-tabler-icons`);
    }
  }
  /**
   * Initialize module with given icons and options
   * @param icons
   * @returns Module with options
   */
  static pick(icons, options) {
    return {
      ngModule: TablerIconsModule,
      providers: [{
        provide: OPTIONS_TOKEN,
        useValue: {
          icons,
          ...options
        },
        multi: true
      }]
    };
  }
  static {
    this.ɵfac = function TablerIconsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TablerIconsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TablerIconsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TablerIconsModule, [{
    type: NgModule,
    args: [{
      imports: [TablerIconComponent],
      exports: [TablerIconComponent]
    }]
  }], () => [], null);
})();
function provideTablerIcons(icons, options) {
  return [{
    provide: OPTIONS_TOKEN,
    useValue: {
      icons,
      ...options
    },
    multi: true
  }];
}

/*
 * Public API Surface of angular-tabler-icons
 */

/**
 * Generated bundle index. Do not edit.
 */

export { TablerIconComponent, TablerIconsModule, provideTablerIcons };
